@import './typography.scss';
@import '../layout/color.scss';
@import '../components/button.scss';
.MuiDialogTitle-root.dialog-title {
  background-color: #faf2e8;
  padding: 10px 12px;
}

.MuiDialogContent-root.dialog-content {
  padding: 8px 12px 12px;
}

.MuiDialogActions-root.dialog-footer {
  padding: 8px 12px;
}

.MuiTypography-body1.dialog-title {
  @extend .fs-16, .fw-semibold, .text-overflow;
}

.MuiTypography-body1.dialog-subtitle {
  @extend .fs-12, .fw-medium, .text-overflow;
}

button.MuiButtonBase-root.MuiButton-root.btn-dialog {
  color: white;
  font-size: 12px;
  font-weight: 600;
  float: right;
}

button.MuiButtonBase-root.MuiButton-root.btn-reset {
  background-color: #ffffff;
  color: #ff9800;
  font-size: 12px;
  font-weight: 600;
  float: right;
  box-shadow: none;
  border: solid 1px #ff9800;
  padding: 0px 7px 0px 4px;
}

svg.MuiSvgIcon-root.dialog-reset-icon {
  width: 16px;
  padding-right: 3px;
  margin-top: 0px;
}

.dialog-content {
  .card-form {
    box-shadow: unset;
    margin: -8px -12px 0px;
  }
}

.dialog-dynamic-content {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ffffff;

  .session {
    flex: 1;
    // display: flex;
    margin-bottom: 2px;
    margin-top: 2px;
    .title {
      @extend .fs-14, .fw-600, .text-overflow;
      color: $primary-color;
      line-height: 18px;
      // display: flex;
    }
    .rightText {
      @extend .fs-14, .fw-600, .text-overflow;
      line-height: 18px;
      color: #212121;
      font-weight: 600;
    }
    .flex {
      display: flex;
    }

    .smTitle {
      @extend .fs-12, .fw-500, .text-overflow;
      font-size: 10px;
      color: $primary-color;
      line-height: 12px;
      margin-top: 2px;
      margin-bottom: 4px;
    }
  }
}
.infoline-content {
  display: flex;
  width: 100%;
  margin-top: 4px;
  .infoline {
    @extend .fs-10, .fw-500;
    color: #212121;
    line-height: 16px;
  }
}
