.preview {
  display: flex;
  width: 100%;
  border: 2px dashed #c5c5c5;
  min-height: 25px;

  padding: 12px;
  margin-bottom: 12px;
}
.expand-formula {
  margin-bottom: 4px;
  &.MuiExpansionPanel-root {
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
    &::before {
      background: none;
    }
    &.Mui-expanded {
      margin: 0px 0px 4px;
    }
  }
  .MuiExpansionPanelSummary-root {
    padding: 8px;
    background: $color-light-blue;

    min-height: unset;
    &.Mui-expanded {
      min-height: unset;
    }
    .MuiExpansionPanelSummary-content {
      margin: 0px;
      .core-list {
        padding-bottom: 0px;
        width: 100%;
        .MuiListItem-root {
          box-shadow: unset;
          background: unset;
          margin: unset;
        }
      }
    }
    .MuiButtonBase-root {
      padding: 0px;
      margin-right: 0px;
      color: #212121;
    }
  }
  .MuiExpansionPanelDetails-root {
    padding: 8px;
    background: #f5f5f5;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;
  }
  .formula-item {
    width: 100%;
    text-align: center;
    background-color: #fff;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
    padding: 6px;
    margin-bottom: 4px;
    cursor: pointer;
  }
}
.calculator {
  text-align: center;
  .MuiGrid-grid-xs-2 {
    flex-grow: 1;
    max-width: 100%;
  }
  .MuiGrid-grid-xs-10 {
    flex-grow: 1;
    max-width: 80.333333%;
  }
  .last-row {
    .zero {
      width: 25%;
    }
    .enter {
      flex: 1;
    }
    .equal {
      height: 76px;
    }
  }
  .MuiButton-root {
    width: 100%;
    min-width: 20px;
    background: $color-light-blue;
  }
}
