@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.image-carousell {
  .img-container {
    background: #f5f6ff;
    &:focus {
      outline: none;
    }
    img {
      width: 100%;
      height: 200px;
      @media (min-width: 600px) {
        object-fit: contain;
        height: 300px;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    display: flex !important;
    // background: #212121;
    justify-content: center;
    margin: auto;
    padding: 6px 0px;
    li {
      &.slick-active {
        button:before {
          color: #78a9ff;
        }
      }
      button:before {
        font-size: 10px;
        color: #454545;
      }
    }
  }
}
