@import '../layout/color.scss';

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    bottom: 0;
    right: 24px;
    &.full-width {
      left: 24px;
      width: calc(100% - 48px);
    }
  }

  .footer-label {
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 24px;
    display: flex;

    .chip-label {
      font-size: 12px;
      font-weight: 500;
      color: $primary-color;
      background: $secondary-color;
      font-size: 12px;
      display: flex;
      align-items: center;
      border-radius: 100px;
      padding: 0px 18px;
    }

    .label-value {
      flex: 1;
      text-align: right;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .footer-btn {
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    @media screen and (min-width: 600px) {
      justify-content: flex-end;
      .MuiButtonBase-root {
        margin-left: 18px;
      }
    }

    .MuiButtonBase-root {
      min-width: 126px;
      &.single {
        margin: 0 auto;
      }
    }
    .MuiButton-label {
      text-transform: capitalize;
    }
    .btn-wrapper {
      padding: 8px 0px;
      width: 100%;
      text-align: center;
    }
  }
}
