//Margin Top
.m-t-4 {
  margin-top: 4px;
}

.m-t-6 {
  margin-top: 6px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-t-14 {
  margin-top: 14px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-18 {
  margin-top: 18px;
}

.m-t-20 {
  margin-top: 20px;
}

//Margin Bottom
.m-b-4 {
  margin-bottom: 4px;
}

.m-b-6 {
  margin-bottom: 6px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-14 {
  margin-bottom: 14px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-b-18 {
  margin-bottom: 18px;
}

.m-b-20 {
  margin-bottom: 20px;
}

//Margin Left
.m-l-4 {
  margin-left: 4px;
}

.m-l-6 {
  margin-left: 6px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-14 {
  margin-left: 14px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-18 {
  margin-left: 18px;
}

.m-l-20 {
  margin-left: 20px;
}

//Margin Right
.m-r-4 {
  margin-right: 4px;
}

.m-r-6 {
  margin-right: 6px;
}

.m-r-8 {
  margin-right: 8px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r-14 {
  margin-right: 14px;
}

.m-r-16 {
  margin-right: 16px;
}

.m-r-18 {
  margin-right: 18px;
}

.m-r-20 {
  margin-right: 20px;
}

//Specific List Width
.unit-width,
.date-width {
  width: 80px;
}
