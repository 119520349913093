div.subheader {
    flex-grow: 1;
    margin: 0;
    position: relative;
    list-style: none;
    margin-bottom: 0;
    border-radius: 0;
    cursor: auto;
    padding: 8px 12px;
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
    .MuiListItemText-primary,
    .MuiListItemText-secondary {
      display: flex;
      align-items: center;
      color: unset;
      flex-wrap: wrap;
    }
    .indicator {
      font-size: 16px;
      margin-right: 2px;
    }
    .flex-space {
      flex: 1;
    }
    .MuiListItemAvatar-root {
      min-width: 50px;
    }
    img.avatar-svg {
      padding: 5px;
      width: 70%;
      height: auto;
    }
    .MuiAvatar-colorDefault {
      background-color: transparent;
    }
    .MuiAvatar-root.avatarProj {
      border-radius: 10%;
      width: 50px;
      margin-right: 10px;
    }
    .MuiAvatar-root.avatarSm {
      width: 30px;
      width: 30px;
    }
    .list-badge span {
      color: #ff1818;
      box-shadow: 0 0 0 2px #fff;
      background-color: #ff1818;
    }
    .list-badge.list-badge-no span {
      color: #fff;
    }
    img.avatar-project {
      max-width: 100%;
      height: 100%;
      object-fit: cover !important;
    }
    img.avatar-img {
      max-width: 100%;
      height: 100%;
      object-fit: cover !important;
    }
    .list-badge .MuiBadge-anchorOriginBottomRightCircle {
      right: 18%;
      top: -14%;
      margin: -26px -49px 12px 0px;
    }
    .list-badge-no span.MuiBadge-badge {
      height: 12px;
      padding: 4px;
      font-size: 8px;
      min-width: 14px;
      font-weight: 600;
      border-radius: 10px;
      padding-top: 5px;
    }
    .list-badge-no .MuiBadge-anchorOriginTopRightRectangle {
      right: 20%;
      top: 6%;
      margin: -20px -49px 12px 0px;
    }
  }
  
  .subheader.subheader-bg-dark {
    background: #bfd3ff;
  }
  
  .subheader.subheader-bg-light {
    background: #e5edff;
  }
  
  .subheader-shadow {
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05),
      0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05),
      0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05),
      0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
  }
  
  // .gl-form-parent.MuiCardContent-root {
  //   padding: 0 10px 0 10px !important;
  //   .icon-vertical-align {
  //     .MuiSvgIcon-root {
  //       vertical-align: middle;
  //     }
  //   }
  //   .gl-export {
  //     font-size: 12px;
  //     background-color: white;
  //     border-radius: 3px;
  //     .MuiSvgIcon-root {
  //       width: 16px;
  //     }
  //     .MuiInputBase-input {
  //       padding: 6px 0 7px 10px;
  //     }
  //   }
  // }
  
  .gl-list-parent {
    margin-top: 1px !important;
  
    .MuiCollapse-wrapper {
      background-color: #cecece;
    }
    .expand-card {
      .MuiExpansionPanelDetails-root {
        padding: 10px 8px 10px 8px;
      }
    }
    .MuiBox-root-12 {
      margin-bottom: 7px;
    }
    .gl-deco {
      text-decoration: underline;
    }
    .desc {
      padding-bottom: 8px;
    }
    .MuiGrid-grid-xs-2 {
      text-align: right;
    }
    .MuiTypography-displayBlock {
      color: black;
    }
    .MuiListItemText-multiline {
      margin-bottom: 0px;
    }
  }
  
  .fix-subheader {
    @media screen and (min-width: 600px) {
      margin-top: 65px !important;
      width: calc(100% - 288px);
      transition: width 0.2s;
      right: 24px;
    }
    width: 100%;
    position: fixed;
    z-index: 100;
    .keyboard1 {
      padding-right: 1px !important;
      padding-left: 4px !important;
      .MuiBox-root {
        width: 100%;
        .MuiTextField-root {
          width: 100%;
        }
      }
    }
    .MuiInput-root {
      border-radius: 3px;
      padding: 2px 0px 2px 8px;
    }
    .MuiSvgIcon-root {
      width: 17px;
      color: black;
    }
    .MuiGrid-grid-xs-2 {
      max-width: 4%;
      flex-basis: 4%;
    }
  
    .MuiGrid-grid-xs-5 {
      max-width: 48%;
      flex-basis: 48%;
    }
  }
  
  
  .fix-subheader-fw {
    @media screen and (min-width: 600px) {
      margin-top: 65px !important;
      width: calc(100% - 48px);
      transition: width 0.2s;
      right: 24px;
    }
    width: 100%;
    position: fixed;
    z-index: 100;
    .keyboard1 {
      padding-right: 1px !important;
      padding-left: 4px !important;
      .MuiBox-root {
        width: 100%;
        .MuiTextField-root {
          width: 100%;
        }
      }
    }
    .MuiInput-root {
      border-radius: 3px;
      padding: 2px 0px 2px 8px;
    }
    .MuiSvgIcon-root {
      width: 17px;
      color: black;
    }
    .MuiGrid-grid-xs-2 {
      max-width: 4%;
      flex-basis: 4%;
    }
  
    .MuiGrid-grid-xs-5 {
      max-width: 48%;
      flex-basis: 48%;
    }
  }
  
  .fix-subheader-infoline{
    @media screen and (min-width: 600px) {
      margin-top: 65px !important;
      width: calc(100% - 288px);
      transition: width 0.2s;
      right: 24px;
      top: 147px;
    }
    width: 100%;
    position: fixed;
    top:112px;
    z-index: 100;
    .keyboard1 {
      padding-right: 1px !important;
      padding-left: 4px !important;
      .MuiBox-root {
        width: 100%;
        .MuiTextField-root {
          width: 100%;
        }
      }
    }
    .MuiInput-root {
      border-radius: 3px;
      padding: 2px 0px 2px 8px;
    }
    .MuiSvgIcon-root {
      width: 17px;
      color: black;
    }
    .MuiGrid-grid-xs-2 {
      max-width: 4%;
      flex-basis: 4%;
    }
  
    .MuiGrid-grid-xs-5 {
      max-width: 48%;
      flex-basis: 48%;
    }
  }
  
  .fix-subheader-infoline-fw{
    @media screen and (min-width: 600px) {
      margin-top: 65px !important;
      width: calc(100% - 48px);
      transition: width 0.2s;
      right: 24px;
      top: 147px;
    }
    width: 100%;
    position: fixed;
    top:112px;
    z-index: 100;
    .keyboard1 {
      padding-right: 1px !important;
      padding-left: 4px !important;
      .MuiBox-root {
        width: 100%;
        .MuiTextField-root {
          width: 100%;
        }
      }
    }
    .MuiInput-root {
      border-radius: 3px;
      padding: 2px 0px 2px 8px;
    }
    .MuiSvgIcon-root {
      width: 17px;
      color: black;
    }
    .MuiGrid-grid-xs-2 {
      max-width: 4%;
      flex-basis: 4%;
    }
  
    .MuiGrid-grid-xs-5 {
      max-width: 48%;
      flex-basis: 48%;
    }
  }
  