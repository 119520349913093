@import './typography.scss';

.timeline {
  .timeline-header {
    padding: 8px 12px;
    @extend .smTitle;
    background: #e2e2e2;
  }
  &.MuiTimeline-root {
    margin: 0px;
    padding: 0px;
    .MuiTimelineItem-missingOppositeContent:before {
      flex: 0;
    }
    .MuiTimelineContent-root {
      overflow: hidden;
    }
    .MuiTimelineDot-root {
      position: relative;
      margin-top: 10px;
    }
    .MuiTimelineConnector-root {
      background: #e2e2e2;
      margin-top: -30px;
    }
  }
  .core-list {
    padding-bottom: 0px;
    .MuiListItem-root {
      box-shadow: unset;
      background: none;
      padding: 0px;
      margin-bottom: 12px;
    }
  }
}
.approval-timeline {
  &.MuiTimeline-root {
    margin: 0px;
    padding: 0px;
    .MuiTimelineItem-missingOppositeContent:before {
      flex: 0;
      padding: 0px;
    }
    .MuiTimelineDot-root {
      position: relative;
      margin-bottom: 0px;
      margin-top: 0px;
      &.dot-default {
        background: #dddddd !important;
      }
    }
    .MuiTimelineDot-root {
      border-color: transparent;
      // background-color: #dddddd;
    }
    .MuiTimelineDot-defaultGrey {
      background-color: #8a8a8a;
    }
    .MuiTimelineConnector-root {
      background: #e2e2e2;
      // margin-top: -30px;
    }
    .MuiTimelineContent-root {
      padding: 0px 12px;
    }
    .core-list {
      padding-bottom: 0px;
      .MuiListItem-root {
        box-shadow: unset;
        background: none;
        padding: 0px;
        margin-bottom: 12px;
      }
    }
  }
}
