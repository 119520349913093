.core-tab {
  // position: fixed !important;
  width: 100%;
  z-index: 100;
  flex-grow: 1;
  .MuiAppBar-colorPrimary {
    color: $color-dark-blue;
    background-color: #ffffff;
    .MuiTab-wrapper {
      text-transform: capitalize;
      font-weight: 600;
    }
    .PrivateTabIndicator-colorSecondary {
      background-color: $color-dark-blue;
    }
  }
  .tab-font {
    font-size: 0.58rem;
  }

  &.single-dynamicInfo {
    top: 106px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);

      top: 194px;
    }
  }
  &.multi-dynamicInfo {
    top: 124px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      top: 210px;
    }
  }
}

.MuiTabs-flexContainer {
  .MuiTab-textColorInherit.Mui-selected {
    white-space: nowrap;
    color: #212121;
  }
}
