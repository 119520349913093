.profile-cover {
    width: 100%;
    height: 138px;
    object-fit: cover;
}

.user-avatar {
    margin: -122px auto 11px;
    border-radius: 100px;
    height: 100px;
    width: 100px;
    display: block;
    object-fit: cover;
}

.upload-user-avatar {
    margin: 0 auto 12px;
    border-radius: 100px;
    height: 100px;
    width: 100px;
    display: block;
    object-fit: cover;
}