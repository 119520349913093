@import './typography.scss';
@import './layout/color.scss';

.profile-container {
  background: url(./../../images/sidemenu-bg.svg);
  // background-size: 100% 100%;
  .profile-avatar {
    padding: 20px 16px 30px;
    margin: 0;
    border-radius: unset;
    .MuiAvatar-colorDefault {
      color: #fafafa;
      background-color: #212121 !important;
    }
    .avatar {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 100%;
    }
  }
}

.profile-list {
  padding: 0px;
  background: #00000075;
  .MuiListItem-root {
    padding: 8px 48px 8px 16px;
    .MuiListItemText-primary,
    .MuiListItemText-secondary {
      display: flex;
      color: #fff;
    }
    .MuiListItemText-root {
      margin: 0px;
    }
  }
  .MuiIconButton-root {
    color: #fff;
  }
}

.route-container {
  padding: 8px 12px;
  @media screen and (min-width: 600px) {
  }
  .MuiListItem-root {
    padding: 6px 8px;
    border: none;
    margin-bottom: 8px;
    &.active {
      background: #fff5e7;
      border: 1px solid #ff9800;
      border-radius: 3px;
      color: #ff9800;
      .icon-svg {
        &:first-child {
          // display: none;
        }
        &:last-child {
          display: block;
        }
      }
      .MuiTypography-root {
        @extend .fw-semibold;
      }
      .MuiListItemIcon-root {
        color: #fff;
      }
    }
    .MuiListItemIcon-root {
      min-width: unset;
    }
    .icon-svg-common {
      width: 23px;
      height: auto;
    }
    .icon-svg {
      width: 23px;
      height: auto;
      &:last-child {
        // display: none;
      }
    }
  }
  .MuiListItemText-root {
    padding-left: 11px;
    .MuiTypography-root {
      @extend .fs-14, .fw-regular;
    }
  }
}

.drawer-dropdown {
  padding: 10px 23px;
  img {
    width: 18px;
    height: auto;
    margin: 0 5px 3px 0;
  }
}

.drawer-submenu {
  margin: 1px 0px 0px -80px;
}
