.tree-view-list {
  width: 100%;

  .MuiTreeItem-content {
    flex-direction: row-reverse;
    .MuiListItem-root {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
    }
    .MuiListItemText-primary,
    .MuiListItemText-secondary {
      display: flex;
      align-items: center;
      color: unset;
      flex-wrap: wrap;
    }
  }
}
